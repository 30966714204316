.ppt-cart-dd-items {
  display: table;
  list-style: none;
  padding: 0px;
  background: white;
  border: 1px solid rgba(88, 89, 91, 1);
  border-top: 10px solid rgba(0, 126, 167, 1);
  z-index: 1;
  width: 400px;
  padding: 10px;
  position: fixed;
  top: 35px;
  right: 48px;
}
.slides-header {
  display: flex;
  border-bottom: 1px solid rgba(88, 89, 91, 1);
}
.clear-all {
  margin-left: auto;
  color: rgba(66, 133, 244, 1);
  cursor: pointer;
}
.clear-all:hover {
  color: rgba(0, 126, 167, 1);
}
.slide-list {
}
.slides-container {
  padding-left: 2px;
  padding-right: 2px;
  max-height: 605px;
}
.ppt-btn-container {
  display: flex;
}
.ppt-download-button {
  cursor: pointer;
  margin-bottom: 14px;
  background-color: rgba(0, 126, 167, 1);
  border-radius: 2px;
  color: white;
  display: inline-block;
  padding: 4px;
  margin-left: auto;
  margin-right: auto;
}

.slide-item {
  display: flex;
}
.cart-item {
  padding: 0px;
}
.ppt-cart {
  cursor: pointer;
  align-items: center;
  width: 19px;
  display: flex;
}

.power-point-icon-selected:hover {
  fill: white;
}
.ppt-cart-selected {
  cursor: pointer;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(0, 126, 167, 1);
  justify-content: center;
}
.ppt-cart-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  width: 28px;
  height: 30px;
}
.ppt-cart-badge {
  position: absolute;
  cursor: pointer;
  right: 50px;
  bottom: 27px;
  background: rgba(0, 126, 167, 1);
  color: white;
  font-size: 10px;
  font-weight: 700;
  white-space: nowrap;
  display: inline-block;
  padding-top: 0px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: center;
  border-radius: 0.25rem;
}

.slide-title {
  font-size: 12pt;
  font-style: italic;
}
.slide-item:hover {
  color: rgba(0, 126, 167, 1);
}

.slide-delete-icon {
  margin-left: 10px;
  cursor: pointer;
  margin-top: -2px;
}

.download-ppt-icon {
  margin-right: 6px;
  margin-bottom: 4px;
}
.download-ppt-icon:hover {
  fill: white;
}

.slide-num {
  margin-right: 8px;
}
