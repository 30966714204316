.tabs-vr-promo-config {
  border-left: 1px solid #bbbfc4;
  height: 100%;
  width: 1px;
}

.week-pri-item {
  padding: 2px;
  text-align: center;
}
.fee-item {
  padding-bottom: 4px;
  padding-top: 4px;
  text-align: left;
}

.week-header {
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  padding-bottom: 8px;
}
.fee-header {
  padding-right: 30px;
  text-align: left;
  padding-bottom: 8px;
}

.weekly-promo-col {
  display: flex;
  flex-grow: 1;
  max-width: 250px;
  margin-bottom: 20px;
  white-space: normal;
}

.weekly-promo-input {
  width: 40px;
  margin-left: auto;
  text-align: right;
}

.ag-react-container .react-select__menu > * {
  padding-top: 0px;
}

.ag-react-container .react-select__menu {
  position: relative;
  top: 20px;
}
