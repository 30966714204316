.download-link {
  margin-top: 10px;
  cursor: pointer;
  color: rgb(0, 126, 167);
}

.download-link:hover {
  text-decoration: underline;
  -webkit-text-decoration: underline;
}
