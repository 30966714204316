.App {
  text-align: center;
}

html {
  height: 100%;
}
.noHover {
  pointer-events: none;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}
.ag-header-cell-text {
  color: white;
}
.ag-set-filter-select-all {
  margin-top: 5px;
}
.ta-logo {
  height: 26px;
  margin-left: 10px;
  position: relative;
  top: 6px;
}
.ta-bullseye {
  position: relative;
  top: 5px;
}
.ta-navbar {
  height: 45px;
  padding: 0px;
  /* box-shadow: 0px 2px 4px rgba(88, 89, 91, 1); */
}
.ta-apps-brand {
  padding-top: 0px;
  padding-bottom: 0px;
}
.container-fluid {
  padding: 0px !important;
  padding-left: 0px;
}
.container {
  padding: 0px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100% !important;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.flex-container {
  list-style: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  min-width: 100px;
  text-align: center;
}

.login-msg {
  margin-bottom: 10px;
  display: flex;
}

.login-heading {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  margin-top: 60px;
}
.login-info {
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  text-align: center;
  font-size: 12px;
}
.login-help {
  position: relative;
  right: -20px;
}
.ta-tabs-header {
  font-size: 18px;
  font-weight: 600;
  color: #58595b;
  padding-right: 10px;
  padding-left: 10px;
}
.ta-tabs-header-icons {
  font-size: 18px;
  font-weight: 600;
  color: #58595b;
}
.input-box {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid 1px #707070;
  background-image: url(img/search.png);
  background-repeat: no-repeat;
  background-position: 0px 6px;
  background-size: 16px;
  text-indent: 24px;
  font-size: 16px;
  height: fit-content;
}

.input-box::placeholder {
  font-style: italic;
  font-size: 16px;
}

.input-box:focus {
  border-bottom-color: #4285f4;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  color: #4285f4;
  background-image: url(img/search-focus.png);
}

.input-box:focus::placeholder {
  color: #4285f4;
}
svg:hover {
  fill: black;
}
.report-list-router-div {
  width: 100%;
  padding-bottom: 10px;
}
.ta-report {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.action-buttons {
  padding-right: 10px;
}
.Toastify__toast--success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
  border-radius: 2px !important;
}
/*
.Toastify__toast--warning {
  background: #f1c40f;
}*/

.Toastify__close-button--success {
  color: #155724 !important;
}

.Toastify__close-button--error {
  color: #721c24 !important;
}

.Toastify__toast--error {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
  border-radius: 2px !important;
}
/*
.Toastify__toast {
} */

/* .btn-primary {
  background-color: rgba(0, 126, 167, 1) !important;
  border-color: 1px solid rgba(0, 126, 167, 1) !important;
} */
.error-div {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 2px;
  font-size: 12px;
}
.validation-error-div {
  max-height: 200px;
  overflow: auto;
}
.error-row {
  white-space: nowrap;
  font-size: 12px;
  padding: 1px 1px 1px 4px;
}

.vr {
  border: 1px solid #ccc;
  height: 85%;
  margin-top: 7px;
}

.menu-item:hover {
  background-color: #cfeaf8;
}

.menu-item:hover > svg {
  fill: black;
}

.gear-menu {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.info-text {
  font-style: italic;
  color: #666;
}
.secondary-app-title {
  margin-right: 0px;
  cursor: default;
  font-weight: 600;
}
.secondary-nav-chevron {
  width: 20px;
  margin-left: 0px;
  margin-right: 20px;
  margin-top: 2px;
}

.ta-button {
  cursor: pointer;
  height: fit-content;
  border-style: none;
  border-radius: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  /* margin-left: 4px;
  margin-right: 4px; */
  /* line-height: 28px; */
  font-size: 16px;
}

.ta-button-primary {
  background-color: rgba(0, 126, 167, 1);
  border: 1px solid rgba(0, 126, 167, 1);
  color: #fff;
}
.ta-button-primary:hover {
  background-color: #006885;
  border: 1px solid #006885;
  /* color: #fff; */
}

.ta-button-secondary {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(112, 112, 112, 1);
  color: rgba(112, 112, 112, 1);
}

.ta-button-secondary:hover {
  background-color: #e0e0e0;
  border: 1px solid rgba(112, 112, 112, 1);
  color: rgba(112, 112, 112, 1);
}

.ta-button-disabled {
  background-color: rgba(181, 181, 181, 1);
  border: 1px solid rgba(181, 181, 181, 1);
  color: rgba(255, 255, 255, 1);
  cursor: not-allowed;
}

.ta-button-danger {
  background-color: #b93f34;
  border: 1px solid #b93f34;
  color: white;
}

.ta-button-danger:hover {
  background-color: #991b1b;
  border: 1px solid #b93f34;
  color: white;
}

.dot {
  animation: ellipsisAnimation 1.5s infinite;
  opacity: 0;
}
.dot:nth-child(1) {
  animation-delay: 0.25s;
}
.dot:nth-child(2) {
  animation-delay: 0.5s;
}
.dot:nth-child(3) {
  animation-delay: 0.75s;
}

@keyframes ellipsisAnimation {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.app-page-title {
  color: rgb(88, 89, 91);
  font-size: 18px;
  font-weight: 600;
}
