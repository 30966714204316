.ta-file-title {
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}
.ta-project-title {
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}
.ta-top-level-folder {
  height: 100%;
  overflow: auto;
}
.ta-folder-selected {
  fill: rgba(0, 126, 167, 1);
}
.draggable {
  display: flex;
  width: 100%;
}
.selected {
  background: #d7e7ff;
}
.upload-folders {
  border: 1px solid lightgray;
  margin-top: 10px;
  height: 300px;
  width: 465px;
  overflow: auto;
}
.new-folder {
  margin-right: auto !important;
}
.disabled {
  background-color: rgba(181, 181, 181, 1) !important;
  border: 1px solid rgba(181, 181, 181, 1);
  color: rgba(255, 255, 255, 1) !important;
  cursor: not-allowed !important;
}
