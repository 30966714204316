.ta-top-level-nav-container > * {
}
.ta-top-level-nav-container {
  width: 100%;
  background-color: rgba(0, 126, 167, 1);
  height: 40px;
}
.ta-top-level-nav > * {
  /* margin-right: 30px; */
  /* padding: 2px; */
}

.ta-center-box svg:hover {
  fill: white;
}
.acc-header {
  padding-top: 58px;
}
.ta-top-level-nav > .selected {
  /* margin-right: 30px; */
  background: rgba(0, 126, 167, 1);
  color: white;
  padding-left: 8px;
  padding-right: 8px;
}
.ta-top-level-nav > .selectable {
  /* margin-right: 30px; */
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
}
.ta-top-level-nav > .gear {
  /* margin-right: 30px; */
  margin-top: 5px;
}
.ta-top-level-nav {
  /* border: 1px solid rgba(0, 126, 167, 1); */
  border-radius: 2px;
  /* padding: 2px; */
}
.ta-second-level-nav > * {
  margin-right: 30px;
}
.ta-second-level-nav > li {
  display: inherit;
}
.ta-second-level-nav {
  padding-top: 8px;
  padding-left: 0px;
}

.ta-overrides > li {
  display: inherit;
}
/* .ta-second-level-nav-container{
  height:100%;
  min-height:100%;
} */

.ta-link-override {
  margin-left: 10px;
}

.ta-second-nav-cont {
  margin-bottom: 10px;
  min-height: 30px;
}
.ta-second-nav-cont-action {
  position: absolute;
  right: 20px;
}

.ta-button {
  height: 28px;
  border-style: none;
  background-color: rgba(0, 126, 167, 1);
  border-radius: 2px;
  border-color: 1px solid rgba(0, 126, 167, 1);
  color: #fff;
}

.ta-button-left {
  margin-right: 20px;
}

.ta-button-right {
  margin-left: 20px;
}

.ta-search {
  height: 25px;
  border-bottom: 1px solid grey;
}
.ta-secondary-nav-link {
  text-decoration: none;
  color: rgba(88, 89, 91, 1);
  padding-left: 4px;
  padding-right: 4px;
}
.ta-active {
  color: rgba(0, 126, 167, 1);
  text-decoration: underline;
  font-weight: bold;
}
.ta-inactive {
  color: blue;
  text-decoration: none;
}

.ta-data-details {
  position: absolute;
  right: 0;
}

.ta-floppy {
  color: blue;
}
.ta-circle-selected {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  background: blue;
  border: 1px solid blue;
  margin-right: 3px;
  margin-top: 3px;
  line-height: 16px;
}

.ta-circle {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  font-size: 16px;
  color: grey;
  text-align: center;
  background: white;
  border: 1px solid grey;
  margin-right: 3px;
  margin-top: 3px;
  line-height: 16px;
}

.ta-center-box {
  display: flex;
  border-right: 1px solid white;
  padding-right: 10px;
  height: 103%;
  align-items: center;
  justify-content: center;
}
.ta-home-box {
  width: 41px;
  border-right: 1px solid white;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ta-home-box-selected {
  background-color: #045976;
}
.home-icon:hover > svg {
  fill:white !important;
}

.ta-nav-icons {
  /* padding-left: 4px;
  padding-right: 4px; */
}

.ta-grid-container {
  height: 100%;
  display: flex;
  width: 100%;
  top: 63px;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}

.ta-nav-labels {
  line-height: 32px;
}

.ta-update-lbl {
  font-weight: bold;
}

.ta-unsaved-lbl {
  padding-left: 8px;
}

.ta-modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  z-index: 9999;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.ta-modal {
  width: 400px;
  height: 100px;
  background-color: #fff;
  border: solid 1px rgba(88, 89, 91, 1);
}
.ta-modal-content {
  position: relative;
  height: 100%;
}
.ta-modal-buttons {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}
.ta-modal-title {
  border-bottom: 1px solid rgba(88, 89, 91, 1);
  text-align: center;
}
.ta-modal-ok-button {
  margin-right: 30px;
}
.app-home {
  cursor: pointer;
  font-weight: 500;
  color: #707070;
}