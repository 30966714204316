.ta-profile {
  cursor: pointer;
  margin-right: 20px;
}
.ta-profile-parent {
  display: flex;
  justify-content: center;
  padding-right: 2px;
  padding-left: 2px;
  height: 100%;
}

.profile-dd-items {
  display: table;
  list-style: none;
  padding: 0px;
  background: white;
  /* color: #007bff; */
  color: black;
  border: 1px solid rgba(88, 89, 91, 1);
  border-top: 10px solid rgba(0, 126, 167, 1);
  z-index: 1;
  width: 300px;
  padding: 5px;
  position: fixed;
  top: 34px;
  right: 21px;
  cursor: default;
}
.profile-dd-item {
  cursor: pointer;
  text-align: right;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: rgba(0, 126, 167, 1);
}
.profile-dd-item:hover {
  color: rgba(0, 126, 167, 1);
  text-decoration: underline;
  text-decoration-color: rgba(0, 126, 167, 1);
  background-color: #cfeaf8;
}
.profile-user-name {
  display: flex;
  border-bottom: 1px solid rgba(88, 89, 91, 1);
  cursor: default;
  margin-bottom: 6px;
}

.profile-selected:hover {
  fill: white;
}

.profile-parent-selected {
  background-color: rgba(0, 126, 167, 1);
}

.profile-selected {
  cursor: pointer;
  align-items: center;
  width: 20px;
  height: 28px;
  display: flex;
  background: rgba(0, 126, 167, 1);
}
