.ta-accubase-nav-link {
  cursor: pointer;
}
.ta-accubase-circle {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  font-size: large;
  color: white;
  text-align: center;
  /* background: rgba(0, 126, 167, 1); */
  background: rgba(247, 157, 51);
  margin-right: 8px;
  line-height: 29px;
  cursor: default;
}
.spacer {
  width: 5%;
}
.chart {
  user-select: none;
  height: 100%;
}

.ta-info-circle {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  font-size: 16px;
  font-style: italic;
  font-family: Baskerville;
  font-weight: 600;
  color: white;
  text-align: center;
  background: rgba(0, 126, 167, 1);
  margin-right: 3px;
  margin-top: 3px;
  line-height: 18px;
  cursor: help;
  display: inline-block;
  padding-top: 1px;
}
.ta-av-input-data-item {
  cursor: pointer;
  font-size: 14px;
}
.ta-av-input-data-item > svg {
  fill: white;
}
.ta-baseline-button {
  height: 28px;
  text-align: center;
  cursor: pointer;
  border-style: none;
  background-color: rgba(0, 126, 167, 1);
  border-radius: 2px;
  border-color: 1px solid rgba(0, 126, 167, 1);
  color: #fff;
  margin-right: 20px;
  padding-left: 8px;
  padding-right: 8px;
}
.ta-av-input-data-item:hover {
  background-color: rgba(66, 133, 244, 0.14);
}
.ta-av-input-data-item:hover .interactive-svg * svg {
  fill: rgba(0, 126, 167, 1);
  transition: all ease 0.3s;
}
.ta-av-input-data-item:hover .interactive-svg > svg {
  fill: rgba(0, 126, 167, 1);
  transition: all ease 0.3s;
}
.ta-project-title-parent:hover .interactive-svg * svg {
  fill: rgba(0, 126, 167, 1);
  transition: all ease 0.3s;
}

.showFill {
  fill: rgba(0, 126, 167, 1) !important;
}
.ta-project-title-parent:hover .interactive-svg > svg {
  fill: rgba(0, 126, 167, 1);
  transition: all ease 0.3s;
}
.ta-baseline-actions * svg {
  fill: white;
}
.selected * svg {
  fill: rgba(0, 126, 167, 1);
}
.ta-baseline-actions:hover .interactive-svg > svg {
  fill: rgba(0, 126, 167, 1);
  transition: all ease 0.3s;
}

.line {
  fill: none;

  stroke-width: 2px;
}
.axisRed text {
  fill: rgb(128, 100, 162);
}
.baseUnit {
  fill: rgb(79, 129, 189);
  color: rgb(79, 129, 189);
  stroke: rgb(79, 129, 189);
}
.elasFitUnits {
  fill: rgb(235, 186, 78);
  color: rgb(235, 186, 78);
  stroke: rgb(235, 186, 78);
}
.baseUnitCircleHit {
  fill: transparent;
  color: transparent;
  stroke: transparent;
}
.baseUnitCircle {
  fill: rgb(79, 129, 189);
  color: rgb(79, 129, 189);
  stroke: rgb(79, 129, 189);
}
.valueUnits {
  fill: rgb(192, 80, 77);
  color: rgb(192, 80, 77);
  stroke: rgb(192, 80, 77);
}
.valueUnitsCircle {
  fill: rgb(192, 80, 77);
  color: rgb(192, 80, 77);
  stroke: rgb(192, 80, 77);
}
.elasFitUnitsCircle {
  fill: rgb(235, 186, 78);
  color: rgb(235, 186, 78);
  stroke: rgb(235, 186, 78);
}
.arpWtd {
  stroke: rgb(155, 187, 89);
  color: rgb(155, 187, 89);
  fill: rgb(155, 187, 89);
}
.arpWtdCircle {
  stroke: rgb(155, 187, 89);
  color: rgb(155, 187, 89);
  fill: rgb(155, 187, 89);
}
.edrpWtdCircle {
  stroke: rgb(128, 100, 162);
  color: rgb(128, 100, 162);
  fill: rgb(128, 100, 162);
}

.edrpWtdCircleHit {
  fill: transparent;
  color: transparent;
  stroke: transparent;
}
.edrpWtd {
  stroke: rgb(128, 100, 162);
  color: rgb(128, 100, 162);
  fill: rgb(128, 100, 162);
}
.axisSteelBlue text {
  fill: rgb(79, 129, 189);
}
.baseline {
  /* stroke-dasharray: 4, 4; */
}
.legend-labels {
  font-size: 12px;
}
.collapse {
  cursor: pointer;
}
.flex-row-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.flex-row-container > .flex-row-item {
  flex-grow: 1;
  flex: 1 1 30%;
  height: 100px;
  margin-bottom: 20px;
}

.flex-row-item {
}

.flex-row-item-child {
  margin-top: 10px;
  flex-direction: row;
  display: flex;
}
.numeric-cell {
  text-align: right;
}

.editable-boolean {
  border-bottom: 1px solid rgba(112, 112, 112, 1);
  text-align: right !important;
  height: 25px;
}

.editable-numeric {
  border-bottom: 1px solid rgba(112, 112, 112, 1);
  height: 25px;
  text-align: right !important;
}

.ag-cell-edit-input {
  text-align: right !important;
}

.nav-disabled {
  color: rgb(126, 126, 126) !important;
  cursor: not-allowed !important;
}

.review-bl-wrapper {
  overflow: visible;
  top: 60px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow-y: auto;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  /* flex: 1 0 100%; */
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
}

.review-lift-chart {
  display: flex;
  background: white;
  /* flex-grow: 1;
  flex-shrink: 1; */
  height: 100%;
  flex-direction: column;
  /* flex-basis: 66%; */
  padding: 20px;
}

.review-bl-chart {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  height: auto;
  flex-direction: column;
  flex-basis: 66%;
}
.review-bl-grid {
  display: flex;
  flex-direction: column;
  /* flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 34%; */
  height: 300px;
  flex: none;
  width: 100%;
  touch-action: none;
}
.bl-grid-resize {
  border-top: 6px solid rgba(225, 225, 225, 1);
}
.bl-grid-noresize {
  margin-top: 10px;
  height: 79%;
}

.tooltip-override {
  z-index: 1000; /*make sure that the tooltip shows above the white svgs in baselinFolder.js*/
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.step-header {
  font-weight: 700;
  font-size: large;
  color: rgba(95, 95, 95, 1);
}

.instructional-text {
  padding-top: 10px;
  font-size: 17px;
  font-style: italic;
  color: rgba(95, 95, 95, 1);
}
.file-instructions {
  font-weight: 400;
  font-size: 14px;

  line-height: 28px;
}
.baseline-card {
  border: 1px solid rgb(229, 230, 232);
  text-align: left;
  width: 300px;
  margin-right: 20px;
  margin-bottom: 20px;
  background: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.add-new-baseline-container {
  background: white;
  position: absolute;
  top: 54px;
  right: 20px;
  padding: 10px;
  border: 1px solid rgb(229, 230, 232);
  width: 280px;
  margin-left: auto;
  height: 245px;
  font-size: 12px;
  z-index: 1;
}
.cancel-button {
  height: 28px;
  border-style: none;

  border-radius: 2px;
  border: 1px solid rgba(181, 181, 181, 1);

  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.card-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.card-details {
  line-height: 18px;
  display: flex;
  margin-right: 30px;
  font-size: 14px;
}
.card-detail-heading {
  color: rgb(102, 102, 102);
  font-size: 14px;
}
.card-detail-value {
  font-weight: 500;
  margin-left: auto;
}
.card-detal-suffix {
  width: 10px;
  padding-left: 2px;
}

.begin-end-dropdown {
  width: 105px;
  height: 31px;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 18px;
}
.country-dropdown {
  width: 185px;
  height: 31px;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 18px;
}
.datepicker {
  padding-right: 10px;
  width: 120px;
  text-align: right;
}
.project-dialog {
  justify-content: flex-end;
}
.day-dropdown {
  width: 170px;
  height: 31px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.disabled-text {
  color: rgb(215, 217, 220);
}
.template-instruct-button {
  border: 1px solid transparent;
  padding: 2px;
  /* width: 225px; */
  text-align: center;
  height: 30px;
}
.template-instruct-dialog {
  position: absolute;
  background: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 1;
  margin-top: 34px;
  right: 0px;
  width: 500px;
  padding: 20px;
  padding-top: 10px;
  border: 1px solid rgba(225, 225, 225, 1);
  font-weight: 400;
  font-size: 14px;

  line-height: 28px;
}
.template-instruct-dialog-open {
  text-align: center;
  background: rgb(237, 237, 237);
  border-radius: 3px;
  color: rgb(54, 127, 167);
  border: 1px solid rgb(237, 237, 237);
}

.ag-header-cell-label > .ag-header-icon > .ag-icon {
  color: white !important;
}

.ag-cell-label-container > .ag-header-icon > .ag-icon-menu {
  color: white !important;
}

.baseline-save {
  border-style: none;
  font-style: italic;
  color: black;
  justify-content: center;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
