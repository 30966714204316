.no-auth-upload {
  margin: 0 auto;
  width: 30%;
  margin-top: 30px;
}

.progress-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.progress-container {
  background-color: #f3f3f3;
  border-radius: 4px;
  height: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.progress-filler {
  background-color: #33b8d4;
  height: 100%;
  border-radius: 4px;
  transition: width 0.3s ease-in-out;
}

.progress-text {
  padding-left: 10px;
  white-space: nowrap;
}
