body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  min-height: 100%;
}

.disabled-card {
  cursor: not-allowed !important;
  color: rgba(181, 181, 181, 1) !important;
}

.disabled-card-menu-item {
  cursor: not-allowed !important;
  color: rgba(181, 181, 181, 1) !important;
}
