.ta-workspace-name {
  font-size: 14px;
  font-weight: 600;
  cursor: default;
  padding-left: 6px;
  padding-bottom: 4px;
  padding-top: 4px;
  width: 100%;
  display: inline-flex;
  cursor: pointer;
}
.ta-workspace-reports-span {
  color: black !important;
}
.ta-workspace-name:hover {
  color: rgba(0, 126, 167, 1);
  text-decoration: underline;
  text-decoration-color: rgba(0, 126, 167, 1);
  background-color: #cfeaf8;
}
.ta-folder-name {
  font-size: 14px;

  font-weight: 600;
  cursor: default;
  padding-left: 6px;
  padding-bottom: 4px;
  padding-top: 4px;
  width: 100%;
  display: inline-flex;
  cursor: pointer;
}
.ta-folder-name-left-caret {
  font-size: 16px;

  font-weight: 600;
  cursor: default;
  padding-left: 0px;
  padding-bottom: 4px;
  padding-top: 4px;
  width: 100%;
  display: inline-flex;
  cursor: pointer;
  color: black;
}
.parent-folder {
  margin-left: 0px;
}

.ta-report-name {
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 14px;
}

.ta-report-name:hover {
  color: rgba(0, 126, 167, 1);
  text-decoration: underline;
  text-decoration-color: rgba(0, 126, 167, 1);
  background-color: #cfeaf8;
}

.ta-report-name:hover > svg {
  fill: rgba(0, 126, 167, 1);
  transition: all ease 0.3s;
}

.ta-pbi-icon {
  margin-right: 4px;
}
.rf-folder-icon {
  padding-right: 5px;
  margin-top: 5px;
  fill: black;
}

.ta-folder-icon {
  padding-right: 4px;
  margin-top: 3px;
}
