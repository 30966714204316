.ta-apps {
  width: 45px;
  height: 45px;
  position: inherit;
  display: inherit;
  background: white;
  padding-top: 3px;
  cursor: pointer;
}
.ta-app-parent {
  display: flex;
  padding-left: 8px;
  /* justify-content: center; */
  align-self: center;
  padding-bottom: 6px;
}
.ta-app-parent:hover > svg {
  fill: rgba(205, 205, 205, 1) !important;
}
.app-menu-open {
  background-color: rgba(0, 126, 167, 1);
}
.app-menu {
  padding-top: 2px;
  padding-right: 6px;
  padding-left: 6px;
  padding-bottom: 6px;
  margin-top: 2px;
}
.app-menu-svg:hover {
  fill: rgba(205, 205, 205, 1) !important;
}
.dd-list-container {
  box-shadow: 0px 19px 19px RGBA(0, 0, 0, 0.26);
  border: 1px solid rgba(181, 181, 181, 181);
  border-top: 10px #007ea7 solid;
  position: relative;
  left: 3px;
  top: 6px;
  max-height: 500px;
  overflow: auto;
  position: absolute;
  padding: 5px;
  background-color: white;
  min-width: 300px;
}

.dd-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: unset;
  width: 100%;
  margin-bottom: 0px;
}
.dd-list-title {
  display:flex;
  flex-direction: row;
  font-size: 16px;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
}
.dd-list-title:hover {
  color: rgba(0, 126, 167, 1);
  text-decoration: underline;
  text-decoration-color: rgba(0, 126, 167, 1);
  background-color: #cfeaf8;
}
.dd-list-subtitle {
  font-size: 16px;
  margin-left: 20px;
}
.dd-list-item {
  cursor: pointer;
  font-size: 14px;
}
.dd-icon-list-item {
  width: 20px;
  margin-right: 6px;
  margin-top: -2px;
  margin-left: 6px;
}
.ta-center-box {
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-list-collapse {
  margin-left: auto;
}

.hover-state:hover {
  color: rgba(0, 126, 167, 1);
  text-decoration: underline;
  text-decoration-color: rgba(0, 126, 167, 1);
  background-color: #cfeaf8;
}

.close-x {
  margin-top: 4px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 4px;
}

.close-x:hover .close-icon {
  fill:black;
}
.close-icon {
  fill:rgba(165, 165, 165, 1);
}

.close-x-subtitle {
  cursor: pointer;
  margin-left: auto;
  margin-right: 4px;
  padding-left: 8px;
}

.close-x-subtitle:hover .close-icon {
  fill:black;
}