.hover {
  color: #045976;
  text-decoration: underline;
  font-weight: bold;
}
.no-hover {
  color: #707070;
}
.child-selected {
  color: #0080ab;
}

.delimiter-style {
  font-weight: 500;
  color: #707070;
} 

.delimiter-base {
  margin-left: 24px;
  font-weight: 500;
  color: #707070;
  margin-top: -1px;
}

.delimiter-hover {
  margin-left: 4px;
  margin-top: -7px;
}

.close-icon-div {
  padding-left: 5px;
  margin-top: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}
