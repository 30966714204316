.dm-secondary-nav-cont {
  margin-top: 10px;
  display: flex;
  margin-bottom: 10px;
}
.dm-secondary-nav-cont > * {
  margin-right: 10px;
  padding: 5px;
  border: 1px solid rgba(112, 112, 112, 1);
  cursor: pointer;
}

.null {
  background-color: yellow;
  width: 100%;
  /* width: 2000px; */
  /* height: 1000px; */
}
/* .ag-set-filter-list {
  margin-top: 20px;
} */
.mdm-action-button {
  height: 28px;
  border-style: none;
  background-color: rgba(0, 126, 167, 1);
  border-radius: 2px;
  border-color: 1px solid rgba(0, 126, 167, 1);
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.mdm-filter-button {
  height: 28px;
  border-style: none;

  border-radius: 2px;
  border: 1px solid rgba(181, 181, 181, 1);

  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.disabled {
  background-color: rgba(181, 181, 181, 1) !important;
  border: 1px solid rgba(181, 181, 181, 1);
  color: rgba(255, 255, 255, 1) !important;
  cursor: not-allowed !important;
}
.mdm-nav-link {
  text-decoration: none;
  color: rgba(88, 89, 91, 1);
  padding-left: 4px;
  padding-right: 4px;
  border: none;
  width: 100%;
}
.mdm-active {
  color: rgba(0, 126, 167, 1);
  text-decoration: underline;
  font-weight: bold;
}
.mdm-inactive {
  color: blue;
  text-decoration: none;
}
.mdm-nav-buttons > button {
  height: 40px;
  margin-right: 20px;
  cursor: pointer;
}

.mdm-circle-selected {
  width: 25px;
  height: 20px;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  background: blue;
  border: 1px solid blue;
  margin-right: 3px;
  margin-top: 3px;
  line-height: 16px;
}

.mdm-circle {
  width: 25px;
  height: 20px;
  border-radius: 10px;
  font-size: 16px;
  color: grey;
  text-align: center;
  background: white;
  border: 1px solid grey;
  margin-right: 3px;
  margin-top: 3px;
  line-height: 16px;
}
.mdm-active {
  color: rgba(0, 126, 167, 1);
  text-decoration: underline;
  font-weight: bold;
}
.mdm-inactive {
  color: blue;
  text-decoration: none;
}
.mdm-button {
  height: 28px;
  border-style: none;
  border-radius: 2px;
  font-size: 16px;
  /* margin-top: 10px; */
  justify-content: center;
  padding-right: 8px;
  padding-left: 8px;
}

.mdm-button-primary {
  background-color: rgba(0, 126, 167, 1);
  border: 1px solid rgba(0, 126, 167, 1);
  color: #fff;
}
.mdm-button:hover {
  background-color: #006885;
  border: 1px solid #006885;
  color: #fff;
}
.mdm-button-secondary {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(112, 112, 112, 1);
  color: rgba(112, 112, 112, 1);
}

.mdm-button-secondary:hover {
  background-color: #e0e0e0;
  border: 1px solid rgba(112, 112, 112, 1);
  color: rgba(112, 112, 112, 1);
}
.mdm-save {
  height: 28px;
  border-style: none;
  font-style: italic;
  color: black;
  /* margin-top: 12px; */
  justify-content: center;
  width: 70px;
  margin-right: 15px;
}
.run-button {
  /* height: 28px; */
  /* border-style: none;
  background-color: rgba(0, 126, 167, 1);
  border-radius: 2px;
  border-color: 1px solid rgba(0, 126, 167, 1);
  color: #fff; */
  /* margin-top: 10px; */
  justify-content: center;
  margin-right: 50px;
}
.running-action {
  height: 28px;
  font-style: italic;
  /* margin-top: 12px; */
  justify-content: center;
  margin-right: 50px;
}
.action-dropdown {
  width: 200px;
  /* height: 31px; */
  /* margin-top: 10px; */
  margin-left: 10px;
  margin-right: 10px;
}
.quick-filter-dropdown {
  width: 300px;
  top: -6px;
  margin-left: 5px;
}
.react-select-qf__placeholder {
  font-style: italic;
}
.reference-tables {
  width: 195px;

  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.react-select__control {
  min-height: 30px !important;
  height: 30px !important;
}
.react-select__indicators {
  height: 28px;
}
.react-select__placeholder {
  height: 28px;
}
.run-action {
  /* margin-top: 14px; */
}
.Toastify__toast-container--top-center {
  top: 50px !important;
}
.editable {
  border-bottom: 1px solid black;
  height: 90%;
  text-align: left;
}
.paste-error {
  background-color: red;
}

.refresh-icon {
  cursor: pointer;
}
