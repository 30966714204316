.all-projects-header {
  background: rgb(54, 127, 167);
  color: white;
  flex-direction: row;
  display: flex;
}
.all-projects-div.open {
  width: 100%;
  border-right: 1px solid rgb(229, 230, 232);
  height: 100%;
}
.all-projects-div {
  border-right: 1px solid rgb(229, 230, 232);
  width: 100%;
  height: 100%;
  /* height: 0%; */
}
.filter-panel-header {
  background: rgb(54, 127, 167);
  color: white;
  flex-direction: row;
  display: flex;
}
.filter-panel-div {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.filter-panel-div.open {
  width: 100%;
  border-left: 1px solid rgb(229, 230, 232);
  height: 100%;
}
.filter-panel-div.open {
  border-left: 1px solid rgb(229, 230, 232);
  width: 100%;
  /* height: 0%; */
}
.accu-object-list-header {
  background: rgb(37, 88, 115);
  color: white;
}
.selected {
  background: rgb(212, 233, 246);
}
.selectedLift {
  text-decoration: underline;
  font-weight: bold !important;
}
.selectedBaseline {
  text-decoration: underline;
  font-weight: bold !important;
}
.table {
  height: 100%;
}
.btn-high-viz {
  /* background-color: #CC7E00; */
  background-color: rgba(0, 126, 167, 1) !important;
  color:white !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.btn-high-viz:hover {
  color:white !important;
}