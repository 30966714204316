.rpt-sub-table td {
  border: 1px solid black;
  text-align: left;
}
.rpt-sub-table th {
  border: 1px solid black;
  text-align: left;
}
.rpt-sub-header {
  background: rgb(232, 232, 232);
}
.on-off-button {
  line-height: 10px !important;
  font-size: 10px !important;
}
.sub-toggle-button-group > * {
  opacity: 1;
}

.sub-toggle-button-group {
  -webkit-user-select: none !important; /* Chrome all / Safari all */
  -moz-user-select: none !important; /* Firefox all */
  -ms-user-select: none !important; /* IE 10+ */
  user-select: none !important;
  height: 28px;
  margin-top: 3px;
  box-shadow: none !important;
  align-items: center;
  margin-right: 20px;
}
.sub-toggle-button-group .active {
  background: rgba(0, 126, 167, 1) !important;
  color: white !important;
}
.sub-toggle-button {
  font-size: 13px !important;
  /* padding-left: 6px !important;
  padding-right: 6px !important;
  padding-top: 6px !important;
  padding-bottom: 7px !important; */
  min-width: 23px !important;
  background-color: white;
  border: 1px solid rgba(88, 89, 91, 1);
  color: black;
  cursor: pointer;
  line-height: 14px !important;
  height: 28px;
}
.sub-toggle-button:hover {
  background-color: white;
  color: black;
  border: 1px solid rgba(88, 89, 91, 1);
}

.sub-toggle-button:active {
  background-color: white !important;
  color: black !important;
}
/* .btn-primary {
  background-color: white !important;
}

.btn-primary:focus {
  box-shadow: none !important;
} */

.sub-toggle-button:focus {
  outline: none;
  box-shadow: none !important;
}
.button-override {
  /* background-color: rgb(126, 126, 126); */
  color: black !important;
}
.button-override::after {
  opacity: 0 !important;
}
.daily-select-group {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}

.run-now-btn {
  border: 1px solid black;
  margin-left: 20px;
  padding: 2px 4px 2px 4px;
  font-size: 14px;
  height: 28px;
  margin-top: 3px;
  cursor: pointer;
  border-radius: 3px;
  margin-right: 20px;
}

.run-now-btn-disabled {
  pointer-events: none;
}

.rpt-sub-modal .modal-content {
  width: 105%;
}
