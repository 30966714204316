.tab-selected {
  font-style: italic;
  color: #0080ab;
}
.tab-unselected {
  border: none;
  color: black;
  background: white;
  padding-top: 2px;
  padding-bottom: 2px;
}
.tab-unselected:hover {
  color: black;
  background-color: #cfeaf8;
}
.tab {
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.close-selected > svg {
  fill: black;
  margin-bottom: 2px;
}
.close-selected:hover > svg {
  fill: rgba(205, 205, 205, 1);
}
.close-unselected > svg {
  fill: rgba(88, 89, 91, 1);
  margin-bottom: 2px;
}
.close-unselected:hover > svg {
  fill: black;
}
.close-no-show {
  display: none;
}
