.ta-second-level-nav > * {
  margin-right: 30px;
}
.ta-second-level-nav > li {
  display: inherit;
}
.ta-second-level-nav {
  padding-top: 8px;
  padding-left: 0px;
}
.acculift {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.all-projects {
  width: 46px;

  transition: width 700ms;
}
.all-projects.open {
  width: 208px;
  max-width: 208px;
  min-width: 208px;
}
.panel-title-bar {
  height: 40px;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}
.panel-title-bar-closed {
  width: 0px;
  transition: width 700ms;
}
.search-closed {
  width: 0px;
  transition: width 700ms;
}
.search-open {
  width: 100%;
  transition: width 700ms;
}

.panel-title-bar-open {
  width: 100%;
  transition: width 700ms;
}
.panel-title-icon {
  margin-left: 10px;
}
.filter-panel {
  width: 46px;
  transition: width 700ms;
}
.filter-panel.open {
  width: 15%;
}
.ta-acculift-nav-link {
  cursor: pointer;
}
.manage-projects-is {
  width: 85%;
  height: 40px;
  min-height: 40px;
  align-items: center;
  margin-left: 20px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
}
.manage-projects-body {
  margin-left: -46px;
  transition: margin 700ms;
  /* margin-right: -30px; */
  height: 100%;
  background: rgb(249, 249, 249);
  border-top: 1px solid rgb(229, 230, 232);
  display: flex;
  flex-direction: column;
  min-height: 0px;
  z-index: 1;
}
.filter-closed {
  margin-right: -46px;
}
.manage-projects-body.open {
  margin-left: -1px;
  /* margin-right: -1px; */
}
.acculift-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.ag-react-container {
  height: 100%;
}
.ag-center-cols-clipper{
  z-index: 0;
}
.accu-project-div {
  background: white;
  height: 100%;
  padding: 20px;
  border: 1px solid rgb(229, 230, 232);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.card {
  border: 1px solid rgb(229, 230, 232);
  text-align: left;
  /*height: 140px;*/
  width: 300px;
  margin-right: 20px;
  margin-bottom: 20px;
  background: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  max-width: 300px;
  min-width: 300px;
  padding-bottom: 15px;
}
.cancel-button {
  height: 28px;
  border-style: none;
  border-radius: 2px;
  border: 1px solid rgba(181, 181, 181, 1);
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.ag-header-row {
  background: rgb(37, 88, 115);
  color: white;
}
.add-new-lift-container {
  background: white;
  position: absolute;
  top: 54px;
  right: 20px;
  padding: 10px;
  border: 1px solid rgb(229, 230, 232);
  width: 250px;
  margin-left: auto;
  height: 170px;
  z-index: 1;
}
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
.grid path {
  stroke-width: 0;
}

.review-lift-wrapper {
  /* overflow: visible; */
  /* position: absolute; */
  /* top: 75px;
  left: 0px;
  right: 0px;
  bottom: 0px; */
  overflow-y: auto;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  /* flex: 1 0 100%; */
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
}
.review-lift-wrapper.open {
  left: 225px;
}

.kpi-container {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
}

.kpi-icon {
  flex-direction: column;
  align-self: center;
  pointer-events: none;
}

.kpi-details {
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
  overflow: hidden;
}

.kpi-title {
  font-size: 14px;
  color: rgb(102, 102, 102);
}

.kpi-value {
  color: black;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.review-lift-wrapper.fopen {
  right: 225px;
}
.project-list-item {
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 20px;
  white-space: nowrap;
}
.scatter-legend {
  border: 1px solid rgb(229, 230, 232);
  width: 200px;
  max-height: 200px;
  padding-left: 8px;
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.legend-heading {
  font-weight: bold;
  margin-bottom: 8px;
}
.legend-desc {
  font-style: italic;
  color: rgb(102, 102, 102);
}
.legend-labels {
  overflow: auto;
}
.legend-svg {
  align-self: center;
  margin-right: 8px;
  width: 18px;
  height: 12px;
}
.icon-wrapper {
  cursor: pointer;
  width: 46px;
  background-color: rgb(54, 127, 167);
  align-items: center;
  display: flex;
}
.tabs-vr {
  border-left: 1px solid #bbbfc4;
  height: 70%;
}
.tabs-hr {
  border-bottom: 1px solid #bbbfc4;
  width: 100%;
}
.lift-model-name {
  font-size: 22px;
}
.project-heading {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.minor-kpi-container {
  margin-left: 8px;
  width: 10%;
}
.minor-kpi {
  font-weight: bold;
  font-size: 14px;
}
.card-details {
  line-height: 18px;
}
.card-detail-heading {
  color: rgb(102, 102, 102);
}
.card-detail-value {
  font-weight: 500;
}
.card-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.actions-button-cell {
  overflow: visible;
  text-align: left;
}
.ag-row.ag-row-focus {
  z-index: 1;
}
.manage-menu-link {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.manage-drop-down {
  /* margin-top: 50px; */
  right: 0px;
  top: 26px;
  position: absolute;
  z-index: 2;
  background: white;
  list-style-type: none;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.manage-menu-item {
  border-bottom: 1px solid #dbddde;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 10px;
  padding-left: 10px;
  align-items: center;
  white-space: nowrap;
}
.manage-menu-item:hover > svg {
  fill: black;
}

.manage-menu-icon {
  width: 26px;
}
.slicer {
  cursor: pointer;
  margin: 3px 2px 3px 2px;
  border: 1px solid #bbbfc4;
  background-color: white;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 3px;
  font-size: 14px;
}
.slicer-selected {
  background-color: #cfeaf8;
}
.project-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  touch-action: none;
}
th,
td {
  padding: 15px;
}
.tool-tip {
  position: absolute;
  font-size: 12px;
  width: auto;
  height: auto;
  pointer-events: none;
  background-color: white;
}
.tool-tip-div {
  border: 1px solid #bbbfc4;
  padding: 10px;
}
.tool-tip-group > td {
  padding: 5px;
  /* display: flex;
  flex-direction: row; */
}
.arrow-down {
  position: relative;
  left: 122px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-top: 20px solid #bbbfc4;
}
.inner-arrow {
  position: relative;
  top: -21px;
  left: -18px;
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 18px solid white;
}
.lift-item {
  font-weight: 400;
}
.chartBox {
  width: 100%;
  height: 100%;
}
/* .disableLift {
  color: rgb(126, 126, 126) !important;
  cursor: not-allowed !important;
} */
.disableLift {
  color: rgb(126, 126, 126) !important;
  cursor: not-allowed !important;
  border: rgb(126, 126, 126) 1px solid ;
}
.baseline-item {
  font-weight: normal !important;
  white-space: normal;
}
