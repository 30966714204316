.user-mgmt-dialog {
  max-width: 600px !important;
  /* height: 80%; */
}

.modal-content {
  height: 100%;
}

.modal-body {
  height: 80%;
}

.user-group-title-div {
  display: flex;
  /* line-height: 30px; */
  margin-bottom: 10px;
}

.ta-add-button-right {
  margin-left: auto !important;
}
