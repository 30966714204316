.notifications-dd-items {
  display: table;
  list-style: none;
  padding: 0px;
  background: white;
  border: 1px solid rgba(88, 89, 91, 1);
  border-top: 10px solid rgba(0, 126, 167, 1);
  z-index: 1;
  width: 600px;
  padding: 10px;
  position: relative;
  right: 576px;
}
.notifications-title-container {
  display: flex;
}
.notifications-title {
  margin-left: 220px;
}
.notifications-selected {
  cursor: pointer;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(0, 126, 167, 1);
  justify-content: center;
  margin-left: -4px;
}
.notifications-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  margin-bottom: 0px;
  width: 28px;
  height: 30px;
}
.notifications-badge {
  position: relative;
  cursor: pointer;
  /* right: 100px; */
  left: 10px;
  bottom: 34px;
  /* background: rgba(0, 126, 167, 1); */
  background: #CC7E00;
  color: white;
  font-size: 10px;
  font-weight: 700;
  white-space: nowrap;
  display: inline-block;
  padding-top: 0px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: center;
  border-radius: 0.25rem;
}
.notification-message-div {
  height: 200px;
  overflow-y: scroll;
  padding-left: 5px;
}

.notifications-icon-selected {
  fill: white !important;
  margin-top: 5px;
}

.notifications-with-count {
  top: 12px;
  position: relative;
  cursor: pointer;
}

.notifications-with-spinner {
  margin-right: 7px;
}
