/* .ag-row:hover {
  background: yellow !important;
} */

.ag-theme-balham .ag-row-hover {
  background: rgba(13, 118, 51, 0.2) !important;
}
.ag-theme-balham .ag-row-selected {
  background: rgba(68, 133, 244, 0.14) !important;
  border-top: 1px solid rgba(66, 133, 244, 1) !important;
  border-bottom: 1px solid rgba(66, 133, 244, 1) !important;
}
.ag-theme-balham .ag-root {
  border: 1px solid #bdc3c7 !important;
}
.ag-font-style {
  color: rgba(88, 89, 91, 1);
}

/* .ag-set-filter-list {
  margin-top: 20px;
} */
.ag-tool-panel {
  border: none;
}

/* .ag-row-focus{
  background: green !important;
} */

.ta-changed {
  background-color: rgba(246, 246, 13, 0.59);
}
.ag-center-cols-clipper {
  z-index: 10;
}
/* .ag-header-cell-resize {
  display:none;
} */

.ag-header-cell-resize {
  background-color: transparent;
  opacity: 0.1;
}
.ag-row {
  /* border-style:none !important; */
}

.totalClass .ag-header-cell-label {
  justify-content: center;
}
.cellCenterClass {
  text-align: center;
}
.headerSize .ag-react-container {
  width: 100%;
}
.future {
  background: rgba(146, 185, 213, 0.19);
}

.ta-odd-row {
  background: rgba(245, 245, 245, 1) !important;
  border-style: none !important;
}

.ta-even-row {
  background: white !important;
  border-style: none !important;
}
.ta-final-group-row-odd {
  background: rgba(245, 245, 245, 1) !important;
  border-bottom: 1px solid grey !important;
}
.ta-final-group-row-even {
  background: white !important;
  border-bottom: 1px solid grey !important;
}
