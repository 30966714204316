.parent-nav-item {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: white;
  position: relative;
  align-items: center;
}
.tab-parent {
  display: flex;
  border-right: 1px solid white;
}
.tab-parent-open {
  background-color: #045976;
}
.tab-parent-selected {
  background-color: #045976;
}
.dd-chevron svg:hover {
  fill: white;
}
.parent-nav-item svg:hover {
  fill: white;
}
.top-level-icon {
  padding-right: 5px;
}
.nav-dropdown {
  position: absolute;
  left: -10px;
  top: 40px;
  min-width: 300px;
  z-index: 2;
  min-height: 100px;
  background: white;
  border-bottom: 1px solid gray;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  box-shadow: 0 19px 19px rgba(0, 0, 0, 0.26);
}
.nav-dropdown-header {
  height: 10px;
  background-color: #045976;
}

.parent-top-level-nav-item {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  border-top: 1px #707070 solid;
  min-height: 40px;
  margin-top: 10px;
  max-height: 400px;
}
