.ag-react-container * .react-select__menu {
  z-index: 2 !important;
  position: fixed !important;
  width: 200px !important;
}

.ag-react-container * .react-select__control {
  min-height: 25px !important;
  height: 25px !important;
  line-height: 16px !important;
}
